import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';

import _ from 'lodash';
import { NextRouter, useRouter } from 'next/router';
import WidgetFollow from 'src/components/widget/widget-follow';
import { SHOW_COMMEMORATE } from 'src/constants';
import { IMenu, IPartner } from 'src/interface/layout';
import { GET } from 'src/services';
import { controller } from 'src/switch-themes';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { splitRote, timestamp } from 'src/utils/helper';

const { name, image } = controller;

const Footer = ({ data }: any) => {
  const [footer, setFooter] = useState<string>('');
  const [partners, setPartner] = useState<IPartner[]>([]);
  const router: NextRouter = useRouter();

  useEffect(() => {
    (async () => {
      try {
        const resData: [string, IPartner[]] = await Promise.all([GET('/api/footer', false, false), GET('/api/v1.0/navigations/company-group', false, false)]);

        if (resData[0]) setFooter(resData[0]);
        if (resData[1]) setPartner(resData[1]);
      } catch (err: any) {
        console.error(`${timestamp()} ==========> MENU_ERROR : `, err.message);
      }
    })();
  }, []);
  const _listMenu: IMenu[] = data;
  const _listPartner: IPartner[] = partners;
  const _copyright = '@ ' + new Date().getFullYear();
  return (
    <FooterWrapper id='footer-wrapper' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
      <div className='footer-wrapper'>
        <div className='footer-list-area container'>
          <div className='block-1 '>
            <p className='about-footer'>ABOUT</p>
            <img src={`${image.logoFooter}`} alt='logo' title='logo' aria-label='logo' width='200' height='100%' />
            {/* {!_.isEmpty(Object.values(address)) && (
              <div className='footer-address'>
                <p className='footer-address-detail'> {Object.values(address).slice(0, 3).join(' ')} </p>
              </div>
            )} */}
            <p
              className='footer-address'
              dangerouslySetInnerHTML={{
                __html: footer
              }}
            />
          </div>
          {!_.isEmpty(_listMenu) && (
            <div className='block-2'>
              <div className='footer-heading'>CATEGORIES</div>
              <ul className='footer-list'>
                {_listMenu.map((item: IMenu, index: number) => (
                  <li key={index} className='list-text'>
                    <a
                      href={`${splitRote(router)}/${item?.link}`}
                      title={item?.nameEng}
                      aria-label={item?.nameEng}
                      // ! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'footer',
                          position: 'on:footer',
                          data: {
                            title: item?.nameTh,
                            heading: 'หมวดหมู่ข่าว',
                            block: '2',
                            index: index + 1
                          }
                        })
                      }
                    >
                      {item?.nameEng}
                    </a>
                  </li>
                ))}
              </ul>

              <div className='footer-heading'>SOCIAL PLATFORM</div>
              <WidgetFollow
                showTitle={false}
                // ! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'follow',
                  position: 'on:footer',
                  data: {
                    block: '1'
                  }
                }}
              />
            </div>
          )}
          {!_.isEmpty(footer) && (
            <div className='block-3 block-partner'>
              <div className='footer-heading'>PARTNER</div>
              <ul className='footer-list'>
                {_listPartner.map((item: IPartner, index: number) => (
                  <li key={index} className='list-text'>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`${item?.link}`}
                      title={item?.nameEng}
                      aria-label={item?.nameEng}
                      // ! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'footer',
                          position: 'on:footer',
                          data: {
                            title: item?.nameEng,
                            heading: 'PARTNER',
                            index: index + 1
                          }
                        })
                      }
                    >
                      {item?.nameEng}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className='block-sonp'>
            <a
              href='http://www.sonp.or.th/'
              target='_blank'
              rel='noopener noreferrer'
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'footer',
                  position: 'on:footer',
                  data: {
                    title: 'http://www.sonp.or.th/',
                    heading: 'สมาคมผู้ผลิตข่าวออนไลน์'
                  }
                })
              }
            >
              <img src={`${image.logoSnop}`} alt='logo' title='logo' className='footer-logo-sonp' width='100%' height='100%' />
            </a>
          </div>
        </div>
        <div className='copyright-area'>
          <div className='container'>
            <div className='copyright-area-content'>
              <p>
                {_copyright}
                <a
                  href={`${splitRote(router)}/`}
                  className='pl-1'
                  // ! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      section: 'footer',
                      position: 'on:footer',
                      data: {
                        title: _.capitalize(name.en),
                        heading: 'copyright'
                      }
                    })
                  }
                >
                  {/* {_.capitalize(name.en)} */}
                  {name.en}
                </a>
                All Rights Reserved.
                <span className='truehit' id='truehits_div' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default memo(Footer);

const FooterWrapper = styled.footer`
  .footer-wrapper {
    background: #27398B;
    font-family: var(--front-secondary), sans-serif;
    .footer-list-area {
      padding: 50px 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        'block1 block2 block2 block3'
        'block-sonp block-sonp block-sonp block-sonp';
      grid-gap: 10px;
      @media (max-width: 768px) {
        grid-template-areas:
          'block1 block1 block2 block2'
          'block3 block3 block3 block3'
          'block-sonp block-sonp block-sonp block-sonp';
      }
      @media (max-width: 690px) {
        grid-template-areas:
          'block1 block1 block1 block1'
          'block3 block3 block3 block3'
          'block2 block2 block2 block2'
          'block-sonp block-sonp block-sonp block-sonp';
      }
    }
    .block-1 {
      grid-area: block1;
      padding: 0 15px;
      .about-footer {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-weight: 100;
        font-size: 26px;
        padding-bottom: 10px;
      }
      img {
        margin: 30px 0;
        width: auto;
      height: auto;
      object-fit: cover;
      }
      .footer-address {
        color: #fff;
        margin-bottom: 30px;
        p {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.45;
        }
      }
    }
    .block-2 {
      grid-area: block2;
      padding: 0 15px;
      width: 70%;
      margin: 0 auto;
      @media (max-width: 690px) {
        width: 100%;
      }
      .footer-heading {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-size: 26px;
        font-weight: 100;
        padding-bottom: 10px;
      }
      .widget-follow {
        padding: 10px 0;
      }
      .footer-list {
        column-count: 2;
        column-gap: 10px;
        text-transform: uppercase;
        padding: 10px;
        .list-text {
          margin: 10px 0;
          &:nth-child(1) {
            margin: 0 0 10px 0;
          }
          a {
            color: #fff;
            padding: 20px 0;
            line-height: 1.45;
            font-weight: 100;
          }
          &:hover a {
            color: #b5b5b5;
          }
        }
      }
    }
    .block-3 {
      grid-area: block3;
      padding: 0 15px;
      color: #fff;
      line-height: 1.45;
      @media (max-width: 690px) {
        margin: 30px 0 30px;
      }
      .footer-heading {
        border-bottom: 2px solid #fff;
        color: #fff;
        font-size: 26px;
        font-weight: 100;
        padding-bottom: 10px;
      }
      .footer-content {
        font-size: 16px;
        p {
          font-weight: 300;
        }
      }
    }
    .block-sonp {
      grid-area: block-sonp;
      text-align: center;
      .footer-logo-sonp {
        width: 250px;
        height: auto;
      }
    }
    .block-partner {
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;
        li {
          font-size: 16px;
          font-weight: 200;
          line-height: 1.45;
          display: inline-block;
          a {
            color: #fff;
            text-transform: capitalize;
          }
          &:hover a {
            color: #b5b5b5;
          }
        }
      }
    }
  }
  .copyright-area {
    padding-top: 15px;
    padding-bottom: 15px;
    background: #27398B;
    /* border-top: 1px solid #292929; */
    margin: 0;

    .copyright-area-content {
      text-align: center;

      p {
        font-weight: 200;
        color: #fff;
        font-size: 16px;
        letter-spacing: 1px;
        margin: 0;
        a {
          display: inline-block;
          color: #fff;
          margin: 0 4px;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
`;
